import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'webcoffee-simple-loading',
    templateUrl: './simple-loading.component.html',
    styleUrls: ['./simple-loading.component.scss'],
    standalone: true,
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleLoadingComponent implements OnInit, OnDestroy {
    @Input() message: string;

    timer1!: any;
    timer2!: any;

    ngOnInit() {
        if (this.message) {
            const dots = document.querySelector('.dots');
            this.animate(dots, 'dots--animate');
        }
    }

    animate(element: any, className: string) {
        element.classList.add(className);
        this.timer1 = setTimeout(() => {
            element.classList.remove(className);
            this.timer2 = setTimeout(() => {
                this.animate(element, className);
            }, 500);
        }, 2500);
    }

    ngOnDestroy() {
        clearTimeout(this.timer1);
        clearTimeout(this.timer2);
    }
}
